import { gql } from "@apollo/client";

import { RECEIPT } from "./Receipt";

export default gql`
	query ($receipt: String!) {
		receiptByReceiptNo(receipt: $receipt) {
			...ReceiptFragment
			relatedOrders {
				...ReceiptFragment
			}
		}
	}
	${RECEIPT}
`;
