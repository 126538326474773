import { gql } from "@apollo/client";

export const RECEIPT = gql`
	fragment ReceiptFragment on Receipt {
		receiptNo
		discounts
		orderDate
		shipping
		taxes
		subtotal
		subtotalWithDiscounts
		total
		currencyCode
		paymentMethod
		last4
		shippingCustomer {
			id
			fullName
			email
			phone
			address1
			address2
			city
			state
			zip
			country
		}
		billingCustomer {
			id
			fullName
			email
			phone
			address1
			address2
			city
			state
			zip
			country
		}
		lineItems {
			id
			title
			quantity
			unitPrice
			subtotal
			productType {
				standardDigital
				recurringDigital
				standardPhysical
				recurringPhysical
				digital
				physical
				recurring
			}
			couponValue
			imageUrl
			imageAltText
			shippable
			recurring
			coupon {
				code
				couponType
				value
			}
			vendorNickname
			shippingNotice {
				id
				carrier
				shippedDate
				trackingNumber
				trackingUrl
				comments
				received
			}
		}
		taxAddress {
			name
			taxId
			address1
			address2
			city
			country
			state
			zip
		}
	}
`;
