import { DSBreakpoints, DSPalette } from "@clickbank-ui/seller-design-system";
import { Box, Container } from "@mui/material";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Receipt from "../components/Receipt";
import { PrivateRoute, PublicOnlyRoute } from "../util/routeHelpers";
import Page404 from "./404Page";
import CreateAccountPage from "./CreateAccountPage";
import AuthenticateDOL from "./DirectOrderLink/AuthenticateDOL";
import ForgotPasswordPage from "./ForgotPasswordPage";
import LegacyOrderRedirect from "./LegacyOrderRedirect/LegacyOrderRedirect";
import LoginForm from "./LoginForm";
import ManageAccount from "./ManageAccount/ManageAccount";
import OrderDetailsPage from "./OrderDetailsPage";
import OrderHistoryPage from "./OrderHistoryPage/OrderHistoryPage";
import OrderLookup from "./OrderLookup";
import VerifyCode from "./VerifyCode/VerifyCode";
import VerifyPasswordReset from "./VerifyPasswordReset/VerifyPasswordReset";
import VerifyUser from "./VerifyUser";

const breakpoints = DSBreakpoints.default;
const { palette } = DSPalette;

const styles = {
	root: {
		paddingTop: "72px",

		[breakpoints.up("md")]: {
			paddingTop: "88px"
		},
		"& .MuiTypography-h4": {
			fontSize: "1.25rem",
			color: palette.text.secondary
		},
		"& .MuiSelect-icon": {
			fontSize: "2.15rem" // something is overriding the DS
		},
		"@media print": {
			padding: 0
		}
	},
	container: {
		paddingTop: "1rem",
		paddingBottom: "1rem",

		[breakpoints.up("md")]: {
			paddingTop: "1.5rem",
			paddingBottom: "1.5rem"
		},
		"@media print": {
			padding: 0
		}
	}
};

export default () => {
	return (
		<Box sx={styles.root}>
			<Container maxWidth="lg" sx={styles.container}>
				<Switch>
					<PublicOnlyRoute exact path="/" component={OrderLookup} />
					<PublicOnlyRoute exact path="/createAccount" component={CreateAccountPage} />
					<Route exact path="/orderDetail.htm" component={LegacyOrderRedirect} />
					<Route exact path="/login" component={LoginForm} />
					<Route exact path="/verifyUser" component={VerifyUser} />
					<Route exact path="/verifyCode" component={VerifyCode} />
					<Route exact path="/verifyPassword" component={VerifyPasswordReset} />
					<Route exact path="/orderDetails/authenticate" component={AuthenticateDOL} />
					<Route exact path="/forgotPassword" component={ForgotPasswordPage} />
					<Route exact path="/error" component={Page404} />
					<PrivateRoute exact path="/manageAccount" component={ManageAccount} />
					<PrivateRoute exact path="/orderHistory" component={OrderHistoryPage} />
					<PrivateRoute exact path="/orderDetails" component={OrderDetailsPage} />
					<PrivateRoute exact path="/receipt" component={Receipt} />
					<Redirect to="/" search={window.location.search} />
				</Switch>
			</Container>
		</Box>
	);
};
