import { DSPalette } from "@clickbank-ui/seller-design-system";
import React from "react";
import { Trans } from "react-i18next";

const { cbNeutral, palette } = DSPalette;

// for maintenance windows
export const disableOrderLookup = false;
export const disableLogin = false;
export const showMaintenanceMessage = false;

// add date range options in months, in ascending order
export const dateRangeOptions = [1, 3, 24];

// there should be a key here for every value in dateRangeOptions
export const dateRangeKeys = {
	1: <Trans i18nKey="orderHistory.Last30Days">Last 30 Days</Trans>,
	3: <Trans i18nKey="orderHistory.Last3months">Last 3 months</Trans>,
	24: <Trans i18nKey="orderHistory.Last2Years">Last 2 Years</Trans>
};

export const statusBarColor = {
	OPEN: palette.warning.light,
	CLOSED: cbNeutral[950],
	ESCALATED: palette.secondary.light,
	PROCESSED: cbNeutral[950]
};
export const statusTextColor = {
	OPEN: palette.warning.dark,
	CLOSED: cbNeutral[200],
	ESCALATED: palette.secondary.dark,
	PROCESSED: cbNeutral[200]
};

export const supportTicketStatus = {
	OPEN: <Trans i18nKey="SupportTicket.statusOpen">Open</Trans>,
	CLOSED: <Trans i18nKey="SupportTicket.statusClosed">Closed</Trans>,
	REOPENED: <Trans i18nKey="SupportTicket.statusReopened">Reopened</Trans>,
	ESCALATED: <Trans i18nKey="SupportTicket.statusEscalated">Escalated</Trans>,
	PROCESSED: <Trans i18nKey="SupportTicket.statusProcessed">Processed</Trans>
};

export const supportTicketType = {
	CANCEL: <Trans i18nKey="SupportTicket.typeCancel">Cancel</Trans>,
	ORDER_LOOKUP: <Trans i18nKey="SupportTicket.typeOrderLookup">Order Lookup</Trans>,
	PRODUCT_CHANGE: <Trans i18nKey="SupportTicket.typeProductChange">Product Change</Trans>,
	REFUND: <Trans i18nKey="SupportTicket.typeRefund">Refund</Trans>,
	TECH_SUPPORT: <Trans i18nKey="SupportTicket.typeTechSupport">Tech Support</Trans>,
	ESCALATED: <Trans i18nKey="SupportTicket.typeEscalated">Escalated</Trans>
};

export const supportTicketActionType = {
	CHANGED: <Trans i18nKey="SupportTicket.actionChanged">Changed</Trans>,
	CLOSED: <Trans i18nKey="SupportTicket.actionClosed">Closed</Trans>,
	COMMENTED: <Trans i18nKey="SupportTicket.actionCommented">Commented</Trans>,
	EXPIRED: <Trans i18nKey="SupportTicket.actionExpired">Expired</Trans>,
	REOPENED: <Trans i18nKey="SupportTicket.actionReopened">Reopened</Trans>
};

export const createTicketTypes = {
	requestRefund: "REFUND",
	cancelSubscription: "CANCEL",
	techSupport: "TECH_SUPPORT"
};

export const editTicketActions = {
	reply: "COMMENT",
	close: "CLOSE",
	techSupport: "CHANGE_TECH_SUPPORT",
	requestRefund: "CHANGE_REFUND",
	cancelSubscription: "CHANGE_CANCEL",
	reopen: "REOPEN",
	escalate: "ESCALATE"
};

export const editTicketMessages = {
	reply: (
		<Trans i18nKey="SupportTicket.ReplySuccess">Your comment has been sent successfully.</Trans>
	),
	close: (
		<Trans i18nKey="SupportTicket.CloseSuccess">
			Your support ticket has been successfully closed.
		</Trans>
	),
	techSupport: (
		<Trans i18nKey="SupportTicket.TechSupportSuccess">
			Your support ticket has been successfully changed to a tech support request.
		</Trans>
	),
	requestRefund: (
		<Trans i18nKey="SupportTicket.RefundSuccess">
			Your support ticket has been successfully changed to a refund request.
		</Trans>
	),
	cancelSubscription: (
		<Trans i18nKey="SupportTicket.CancelSuccess">
			Your support ticket has been successfully changed to a cancellation request.
		</Trans>
	),
	reopen: (
		<Trans i18nKey="SupportTicket.ReopenSuccess">
			Your support ticket has been successfully reopened.
		</Trans>
	),
	escalate: (
		<Trans i18nKey="SupportTicket.EscalateSuccess">
			Your support ticket has been successfully escalated to ClickBank.
		</Trans>
	)
};

export function getTechSupportTopics({ productType: { digital } }) {
	return [
		...(digital
			? [
					"ticket.type.tech_support.1",
					"ticket.type.tech_support.2",
					"ticket.type.tech_support.3"
			  ]
			: []),
		"ticket.type.tech_support.4",
		"ticket.type.tech_support.10",
		"ticket.type.tech_support.9"
	];
}

export function getRefundRequestTopics({ productType: { shippable, digital } }) {
	console.log("shippable", shippable, digital);
	return [
		"ticket.type.refund.1",
		"ticket.type.refund.2",
		...(digital
			? ["ticket.type.refund.3", "ticket.type.refund.not.mobile", "ticket.type.refund.4"]
			: []),
		"ticket.type.refund.5",
		"ticket.type.refund.6",
		"ticket.type.refund.7",
		...(shippable ? ["ticket.type.refund.returned"] : []),
		"ticket.type.refund.8"
	];
}

export function getSubscriptionCancellationTopics({ productType: { digital } }) {
	console.log("digital", digital);
	return [
		"ticket.type.cancel.1",
		"ticket.type.cancel.2",
		"ticket.type.cancel.3",
		...(digital ? ["ticket.type.cancel.4", "ticket.type.cancel.not.mobile"] : []),
		"ticket.type.cancel.5",
		"ticket.type.cancel.6",
		"ticket.type.cancel.7"
	];
}

export const supportTicketTopic = {
	"cancels@clickbank.com": (
		<Trans i18nKey="SupportTicket.cancelMail">cancels@clickbank.com</Trans>
	),
	"csaccount.subject.accountClose": (
		<Trans i18nKey="SupportTicket.AccClose">Account Closure Requests</Trans>
	),
	"csaccount.subject.affiliateSetup": (
		<Trans i18nKey="SupportTicket.affiliateSetup">Affiliate Set Up Questions</Trans>
	),
	"csaccount.subject.marketing": (
		<Trans i18nKey="SupportTicket.marketing">Newsletter or Press Release Questions</Trans>
	),
	"csaccount.subject.marketplace": (
		<Trans i18nKey="SupportTicket.marketplace">Marketplace Questions</Trans>
	),
	"csaccount.subject.newCategory": (
		<Trans i18nKey="SupportTicket.newCategory">
			New Marketplace Category or Subcategory Suggestion
		</Trans>
	),
	"csaccount.subject.other": <Trans i18nKey="SupportTicket.other">Other</Trans>,
	"csaccount.subject.password": (
		<Trans i18nKey="SupportTicket.password">Password Inquiries</Trans>
	),
	"csaccount.subject.payment": <Trans i18nKey="SupportTicket.payment">Payment Inquiries</Trans>,
	"csaccount.subject.productImage": (
		<Trans i18nKey="SupportTicket.productImage">Product Image Question</Trans>
	),
	"csaccount.subject.vendorSetup": (
		<Trans i18nKey="SupportTicket.vendorSetup">Vendor Set Up Questions</Trans>
	),
	escalation: <Trans i18nKey="SupportTicket.escalation">Escalation</Trans>,
	"jsp.ticket_detail.account_questions_desc": (
		<Trans i18nKey="SupportTicket.account_questions_desc">
			Account Question Ticket via email
		</Trans>
	),
	"refund.reason.other": <Trans i18nKey="SupportTicket.other">Other</Trans>,
	"refunds@clickbank.com": (
		<Trans i18nKey="SupportTicket.refundMail">refunds@clickbank.com</Trans>
	),
	"ticket.business.development": (
		<Trans i18nKey="SupportTicket.development">Business Development</Trans>
	),
	"ticket.desc.eudd.failed.deposit": (
		<Trans i18nKey="SupportTicket.failedDeposit">EUDD Failed Deposit</Trans>
	),
	"ticket.order.lookup": <Trans i18nKey="SupportTicket.lookUp">Order Lookup</Trans>,
	"ticket.type.cancel.1": (
		<Trans i18nKey="SupportTicket.cancel1">
			I did not receive additional value for the recurring payments.
		</Trans>
	),
	"ticket.type.cancel.2": (
		<Trans i18nKey="SupportTicket.cancel2">
			I was not satisfied with the subscription or it did not meet my expectations.
		</Trans>
	),
	"ticket.type.cancel.3": (
		<Trans i18nKey="SupportTicket.cancel3">I was unable to get support from the vendor.</Trans>
	),
	"ticket.type.cancel.4": (
		<Trans i18nKey="SupportTicket.cancel4">Product was not compatible with my computer.</Trans>
	),
	"ticket.type.cancel.5": (
		<Trans i18nKey="SupportTicket.cancel5">
			I am unable to afford continuing payments for this subscription.
		</Trans>
	),
	"ticket.type.cancel.6": (
		<Trans i18nKey="SupportTicket.cancel6">
			I did not realize that I accepted the terms of a subscription.
		</Trans>
	),
	"ticket.type.cancel.7": <Trans i18nKey="SupportTicket.cancel7">Other</Trans>,
	"ticket.type.cancel.fraudulent": <Trans i18nKey="SupportTicket.fraudulent">Fraudulent</Trans>,
	"ticket.type.cancel.not.mobile": (
		<Trans i18nKey="SupportTicket.notMobile">
			Product was not compatible with my mobile device.
		</Trans>
	),
	"ticket.type.refund.1": (
		<Trans i18nKey="SupportTicket.refund1">I never received my product.</Trans>
	),
	"ticket.type.refund.2": (
		<Trans i18nKey="SupportTicket.refund2">
			I was not satisfied with the product. / Product did not meet expectations.
		</Trans>
	),
	"ticket.type.refund.3": (
		<Trans i18nKey="SupportTicket.refund3">Product was not compatible with my computer.</Trans>
	),
	"ticket.type.refund.4": (
		<Trans i18nKey="SupportTicket.refund4">I was unable to get technical support.</Trans>
	),
	"ticket.type.refund.5": (
		<Trans i18nKey="SupportTicket.refund5">I did not authorize the purchase.</Trans>
	),
	"ticket.type.refund.6": (
		<Trans i18nKey="SupportTicket.refund6">I do not recognize the purchase.</Trans>
	),
	"ticket.type.refund.7": (
		<Trans i18nKey="SupportTicket.refund7">
			Duplicate purchase. / Or already purchased product previously.
		</Trans>
	),
	"ticket.type.refund.8": (
		<Trans i18nKey="SupportTicket.refund8">Other (Please explain in comments below).</Trans>
	),
	"ticket.type.refund.fraudulent": <Trans i18nKey="SupportTicket.fraudulent">Fraudulent</Trans>,
	"ticket.type.refund.not.mobile": (
		<Trans i18nKey="SupportTicket.notMobile">
			Product was not compatible with my mobile device.
		</Trans>
	),
	"ticket.type.refund.reported.transaction": (
		<Trans i18nKey="SupportTicket.reportedTransaction">Reported Transaction</Trans>
	),
	"ticket.type.refund.returned": <Trans i18nKey="SupportTicket.returned">Product Returned</Trans>,
	"ticket.type.tech_support.1": (
		<Trans i18nKey="SupportTicket.techSupport1">I am unable to log in.</Trans>
	),
	"ticket.type.tech_support.10": (
		<Trans i18nKey="SupportTicket.techSupport10">I never received my product.</Trans>
	),
	"ticket.type.tech_support.11": (
		<Trans i18nKey="SupportTicket.techSupport11">
			I do not recognize this purchase and would like more information.
		</Trans>
	),
	"ticket.type.tech_support.2": (
		<Trans i18nKey="SupportTicket.techSupport2">I had problems downloading the product.</Trans>
	),
	"ticket.type.tech_support.3": (
		<Trans i18nKey="SupportTicket.techSupport3">
			I never received a valid registration code, please send a valid code.
		</Trans>
	),
	"ticket.type.tech_support.4": (
		<Trans i18nKey="SupportTicket.techSupport4">I can&apos;t get the product to work.</Trans>
	),
	"ticket.type.tech_support.9": <Trans i18nKey="SupportTicket.techSupport9">Other</Trans>
};

export const emailUsTopics = [
	{
		id: 1,
		topic: (
			<Trans i18nKey="EmailUsModal.problem.dontRecognize">
				I don't recognize this charge.
			</Trans>
		)
	},
	{
		id: 2,
		topic: <Trans i18nKey="EmailUsModal.problem.support">I need support for my purchase.</Trans>
	},
	{
		id: 3,
		topic: (
			<Trans i18nKey="EmailUsModal.problem.cancel">I need to cancel my subscription.</Trans>
		)
	},
	{
		id: 4,
		topic: (
			<Trans i18nKey="EmailUsModal.problem.neverReceived">I never received my order.</Trans>
		)
	},
	{ id: 5, topic: <Trans i18nKey="EmailUsModal.problem.refund">I need a refund.</Trans> },
	{
		id: 6,
		topic: (
			<Trans i18nKey="EmailUsModal.problem.somethingElse">
				I need help with something else.
			</Trans>
		)
	}
];

export const countryList = {
	BD: "Bangladesh",
	BE: "Belgium",
	BF: "Burkina Faso",
	BG: "Bulgaria",
	BA: "Bosnia and Herzegovina",
	BB: "Barbados",
	WF: "Wallis and Futuna",
	BL: "Saint Barthelemy",
	BM: "Bermuda",
	BN: "Brunei",
	BO: "Bolivia",
	BH: "Bahrain",
	BI: "Burundi",
	BJ: "Benin",
	BT: "Bhutan",
	JM: "Jamaica",
	BV: "Bouvet Island",
	BW: "Botswana",
	WS: "Samoa",
	BQ: "Bonaire, Saint Eustatius and Saba ",
	BR: "Brazil",
	BS: "Bahamas",
	JE: "Jersey",
	BY: "Belarus",
	BZ: "Belize",
	RU: "Russia",
	RW: "Rwanda",
	RS: "Serbia",
	TL: "East Timor",
	RE: "Reunion",
	TM: "Turkmenistan",
	TJ: "Tajikistan",
	RO: "Romania",
	TK: "Tokelau",
	GW: "Guinea-Bissau",
	GU: "Guam",
	GT: "Guatemala",
	GS: "South Georgia and the South Sandwich Islands",
	GR: "Greece",
	GQ: "Equatorial Guinea",
	GP: "Guadeloupe",
	JP: "Japan",
	GY: "Guyana",
	GG: "Guernsey",
	GF: "French Guiana",
	GE: "Georgia",
	GD: "Grenada",
	GB: "United Kingdom",
	GA: "Gabon",
	SV: "El Salvador",
	GN: "Guinea",
	GM: "Gambia",
	GL: "Greenland",
	GI: "Gibraltar",
	GH: "Ghana",
	OM: "Oman",
	TN: "Tunisia",
	JO: "Jordan",
	HR: "Croatia",
	HT: "Haiti",
	HU: "Hungary",
	HK: "Hong Kong",
	HN: "Honduras",
	HM: "Heard Island and McDonald Islands",
	VE: "Venezuela",
	PR: "Puerto Rico",
	PS: "Palestinian Territory",
	PW: "Palau",
	PT: "Portugal",
	SJ: "Svalbard and Jan Mayen",
	PY: "Paraguay",
	IQ: "Iraq",
	PA: "Panama",
	PF: "French Polynesia",
	PG: "Papua New Guinea",
	PE: "Peru",
	PK: "Pakistan",
	PH: "Philippines",
	PN: "Pitcairn",
	PL: "Poland",
	PM: "Saint Pierre and Miquelon",
	ZM: "Zambia",
	EH: "Western Sahara",
	EE: "Estonia",
	EG: "Egypt",
	ZA: "South Africa",
	EC: "Ecuador",
	IT: "Italy",
	VN: "Vietnam",
	SB: "Solomon Islands",
	ET: "Ethiopia",
	SO: "Somalia",
	ZW: "Zimbabwe",
	SA: "Saudi Arabia",
	ES: "Spain",
	ER: "Eritrea",
	ME: "Montenegro",
	MD: "Moldova",
	MG: "Madagascar",
	MF: "Saint Martin",
	MA: "Morocco",
	MC: "Monaco",
	UZ: "Uzbekistan",
	MM: "Myanmar",
	ML: "Mali",
	MO: "Macao",
	MN: "Mongolia",
	MH: "Marshall Islands",
	MK: "Macedonia",
	MU: "Mauritius",
	MT: "Malta",
	MW: "Malawi",
	MV: "Maldives",
	MQ: "Martinique",
	MP: "Northern Mariana Islands",
	MS: "Montserrat",
	MR: "Mauritania",
	IM: "Isle of Man",
	UG: "Uganda",
	TZ: "Tanzania",
	MY: "Malaysia",
	MX: "Mexico",
	IL: "Israel",
	IC: "Canary Islands",
	FR: "France",
	IO: "British Indian Ocean Territory",
	SH: "Saint Helena",
	FI: "Finland",
	FJ: "Fiji",
	FK: "Falkland Islands",
	FM: "Micronesia",
	FO: "Faroe Islands",
	NI: "Nicaragua",
	NL: "Netherlands",
	NO: "Norway",
	NA: "Namibia",
	VU: "Vanuatu",
	NC: "New Caledonia",
	NE: "Niger",
	NF: "Norfolk Island",
	NG: "Nigeria",
	NZ: "New Zealand",
	NP: "Nepal",
	NR: "Nauru",
	NU: "Niue",
	CK: "Cook Islands",
	XK: "Kosovo",
	CI: "Ivory Coast",
	CH: "Switzerland",
	CO: "Colombia",
	CN: "China",
	CM: "Cameroon",
	CL: "Chile",
	CC: "Cocos Islands",
	CA: "Canada",
	CG: "Republic of the Congo",
	CF: "Central African Republic",
	CD: "Democratic Republic of the Congo",
	CZ: "Czech Republic",
	CY: "Cyprus",
	CX: "Christmas Island",
	CR: "Costa Rica",
	CW: "Curacao",
	CV: "Cape Verde",
	CU: "Cuba",
	SZ: "Swaziland",
	SY: "Syria",
	SX: "Sint Maarten",
	KG: "Kyrgyzstan",
	KE: "Kenya",
	SS: "South Sudan",
	SR: "Suriname",
	KI: "Kiribati",
	KH: "Cambodia",
	KN: "Saint Kitts and Nevis",
	KM: "Comoros",
	ST: "Sao Tome and Principe",
	SK: "Slovakia",
	KR: "South Korea",
	SI: "Slovenia",
	KP: "North Korea",
	KW: "Kuwait",
	SN: "Senegal",
	SM: "San Marino",
	SL: "Sierra Leone",
	SC: "Seychelles",
	KZ: "Kazakhstan",
	KY: "Cayman Islands",
	SG: "Singapore",
	SE: "Sweden",
	SD: "Sudan",
	DO: "Dominican Republic",
	DM: "Dominica",
	DJ: "Djibouti",
	DK: "Denmark",
	VG: "British Virgin Islands",
	DE: "Germany",
	YE: "Yemen",
	DZ: "Algeria",
	US: "United States",
	UY: "Uruguay",
	YT: "Mayotte",
	UM: "United States Minor Outlying Islands",
	LB: "Lebanon",
	LC: "Saint Lucia",
	LA: "Laos",
	TV: "Tuvalu",
	TW: "Taiwan",
	TT: "Trinidad and Tobago",
	TR: "Turkey",
	LK: "Sri Lanka",
	LI: "Liechtenstein",
	LV: "Latvia",
	TO: "Tonga",
	LT: "Lithuania",
	LU: "Luxembourg",
	LR: "Liberia",
	LS: "Lesotho",
	TH: "Thailand",
	TF: "French Southern Territories",
	TG: "Togo",
	TD: "Chad",
	TC: "Turks and Caicos Islands",
	LY: "Libya",
	VA: "Vatican",
	VC: "Saint Vincent and the Grenadines",
	AE: "United Arab Emirates",
	AD: "Andorra",
	AG: "Antigua and Barbuda",
	AF: "Afghanistan",
	AI: "Anguilla",
	VI: "U.S. Virgin Islands",
	IS: "Iceland",
	IR: "Iran",
	AM: "Armenia",
	AL: "Albania",
	AO: "Angola",
	AQ: "Antarctica",
	AS: "American Samoa",
	AR: "Argentina",
	AU: "Australia",
	AT: "Austria",
	AW: "Aruba",
	IN: "India",
	AX: "Aland Islands",
	AZ: "Azerbaijan",
	IE: "Ireland",
	ID: "Indonesia",
	UA: "Ukraine",
	QA: "Qatar",
	MZ: "Mozambique"
};

export const taxName = {
	// countries that use VAT
	vat: [
		"AT",
		"BE",
		"CY",
		"CZ",
		"DK",
		"EE",
		"ES",
		"FI",
		"FR",
		"GB",
		"GR",
		"HU",
		"IE",
		"IT",
		"LT",
		"LU",
		"LV",
		"MT",
		"NL",
		"PL",
		"PT",
		"SE",
		"SK",
		"DE",
		"CH",
		"NO",
		"IS",
		"HR",
		"SI",
		"RO",
		"BG",
		"ZA",
		"KR",
		"JP",
		"RU",
		"TW"
	],
	//countries that use GST
	gst: ["AU", "NZ"]
};
