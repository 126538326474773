import { useLazyQuery } from "@apollo/client";
import { ChevronLeft, Print } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import { RECEIPT_BY_RECEIPT } from "../../Api";
import { getReceiptFromDolToken } from "../../util/authHelpers.js";
import ReceiptContent from "./ReceiptContent.js";

export default () => {
	const history = useHistory();

	const [orderDetails, setOrderDetails] = useState(undefined);
	const [receiptNo, setReceiptNo] = useState("");

	const [getReceipt, { data: receiptData, error: receiptError }] = useLazyQuery(
		RECEIPT_BY_RECEIPT,
		{
			variables: { receipt: receiptNo }
		}
	);

	const printReceipt = () => {
		window.print();
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const receiptNo = urlParams.get("receiptNo") || getReceiptFromDolToken();
		setReceiptNo(receiptNo);
		getReceipt();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (receiptData) {
			setOrderDetails(receiptData.receiptByReceiptNo);
		}
		receiptError && history.push("/orderHistory?status=UnableToFindOrder");
	}, [receiptData, receiptError, history]);

	return (
		<>
			<Stack
				direction={{ xs: "column", sm: "row" }}
				justifyContent="space-between"
				sx={{ "@media print": { display: "none" } }}
			>
				<Button
					size="small"
					color="secondary"
					startIcon={<ChevronLeft />}
					sx={{ mb: { xs: 1, sm: 0 } }}
					id="backtoOrderHistory"
					onClick={() => {
						history.push("/");
					}}
				>
					{getReceiptFromDolToken() === "NO_RECEIPT_FOUND" ? (
						// We might be able to do better: Button will not be displayed if authenticated via DOL and login.
						<Trans i18nKey="Common.backToOrderHistory">Back to Order History</Trans>
					) : (
						<Trans i18nKey="Common.customerSupportHome">Customer Support Home</Trans>
					)}
				</Button>
				<Button
					size="small"
					color="primary"
					startIcon={<Print />}
					id="print"
					onClick={printReceipt}
				>
					<Trans i18nKey="Common.print">Print</Trans>
				</Button>
			</Stack>

			<ReceiptContent details={orderDetails} />

			{orderDetails?.relatedOrders?.map((order, index) => (
				<ReceiptContent details={order} key={index} />
			))}
		</>
	);
};
